<template>
  <b-modal id="modal-add-member-enterprise" no-close-on-backdrop hide-header hide-footer centered>
    <div class="d-flex flex-column">
      <span class="title-admin-enterprise">Add seat</span>
      <span class="my-3 enterprise-member-list-text">Available storage: {{convertByteToGB(myEnterprise.available_storage)}} GB.</span>
      <div v-if="myEnterprise.available_free_user > 0" class="mb-3">
        <span class="enterprise-member-list-text-bg">Free seat: {{myEnterprise.available_free_user}}</span>
      </div>
      <div v-else class="mb-3 enterprise-add-bg-info">
        <span class="enterprise-member-list-text">You will be charged {{priceSeat}} for this additional seat.</span>
      </div>
      <form action="" class="my-3">
        <div class="mb-3 enterprise-form-control">
          <input
            type="text"
            placeholder="Email"
            v-model.trim="$v.email.$model"
            class=""
            :class="{'is-invalid': validationStatus($v.email)}">
          <div class="invalid-feedback">
                <span v-if="!$v.email.required">Email is required.</span>
                <span v-if="!$v.email.email" id="email-error" class="required-color error-message-v3">Please enter a valid email (e.g., example@mail.com).</span>
          </div>
        </div>
        <div class="d-flex justify-content-start">
          <div class="enterprise-form-control">
            <input
              type="number"
              placeholder="Storage size (GB)"
              v-model.trim="$v.givenSize.$model"
              :class="{'is-invalid': validationStatus($v.givenSize)}">
            <div class="invalid-feedback">
                  <span v-if="!$v.givenSize.required">Size storage is required.</span>
                  <span v-else-if="!$v.givenSize.maxValue">The seat’s storage size failed to save because it exceeds the enterprise’s available storage.</span>
                  <span v-else-if="!$v.givenSize.minValue">Please enter a number greater than 0.</span>
                  <span v-else-if="!$v.givenSize.cantStartWithZero">Please enter a number greater than 0.</span>
            </div>
          </div>
          <span class="value-size-form ml-3">GB</span>
        </div>
      </form>
      <div class="d-flex justify-content-end align-items-center mt-3 close-and-submit-enterprise">
        <span class="mr-3" @click="close()">Cancel</span>
        <button
          @click="submit()"
          :disabled="$v.$invalid || email == null || givenSize == null"
          class="ml-3"
          >
          <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
          Save</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { required, email, minValue, maxValue } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      email: null,
      givenSize: null,
      loading: false
    }
  },
  props: ["priceSeat"],
  validations() {
    return {
      email: {
        required,
        email,
        isUnique (value) {
          if (value === '') return true
          var email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(email_regex.test(value))
            }, 350 + Math.random()*300)
          })
        }
      },
      givenSize: {
        required,
        minValue: minValue(1),
        cantStartWithZero (value) {
          if (value === '') return true
          var regex = /^[1-9][0-9]*$/;
  
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(regex.test(value))
            }, 350 + Math.random()*300)
          })
        },
        maxValue: maxValue(this.convertByteToGB(this.myEnterprise.available_storage))
      }
    }
  },
  mounted(){
    this.reset()
  },
  computed: {
    myEnterprise(){
      return this.$store.getters['enterprise/myEnterprise']
    },
    enterprisePlan(){
      return this.$store.getters['enterprise/planEnterprise']
    }
  },
  methods: {
    reset(){
      this.givenSize = this.convertByteToGB(this.myEnterprise.min_user_storage)
    },
    convertByteToGB(byte) {
      var gigabyte = byte / (1024 * 1024 * 1024);
      return gigabyte.toFixed(0);
    },
    convertGBToByte(gigabytes) {
      var byte = gigabytes * Math.pow(1024, 3);
      return byte.toFixed(0);
    },
    validationStatus(validation) {
      return typeof validation != undefined ? validation.$error: false
    },
    close() {
      this.$bvModal.hide('modal-add-member-enterprise')
      this.email = null
      this.givenSize = this.convertByteToGB(this.myEnterprise.min_user_storage)
    },
    submit() {
      this.loading = true
      var payload = {
        email: this.email,
        enterprise: this.myEnterprise._id,
        size: parseInt(this.convertGBToByte(this.givenSize))
      }
      this.$store.dispatch('enterprise/addSeat', payload)
      .then(()=>{
        this.$bvModal.hide('modal-add-member-enterprise')
        this.$toasted.show(`Seat added successfully.`,{ 
          theme: "toasted-primary", 
          position: "bottom-center", 
          duration : 2000,
          containerClass : 'toast-snackbar'
        })
        this.email = null
        this.givenSize = this.convertByteToGB(this.myEnterprise.min_user_storage)
        this.loading = false
      })
    },
  }
}
</script>

<style>

</style>