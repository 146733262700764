<template>
  <b-modal id="modal-edit-member-enterprise" no-close-on-backdrop hide-header hide-footer centered>
    <div class="d-flex flex-column">
    <!-- <pre>{{member}}</pre> -->
    <!-- <pre>{{currentEnterprise}}</pre> -->
      <span class="title-admin-enterprise">Edit seat</span> 
      <span class="my-3 enterprise-member-list-text">Used storage: {{convertByteToGB(currentEnterprise.available_storage)}} GB.</span>
      <form action="" class="my-3">
        <div class="mb-3 enterprise-form-control">
          <input
            disabled
            type="text"
            placeholder="Email"
            v-model="member.email">
        </div>
        <div class="d-flex justify-content-start">
          <div class="enterprise-form-control">
            <input
              type="number"
              placeholder="Storage size (GB)"
              v-model.trim="$v.givenSize.$model"
              :class="{'is-invalid': validationStatus($v.givenSize)}">
            <div class="invalid-feedback">
              <span v-if="!$v.givenSize.required">Size storage is required.</span>
              <span v-else-if="!$v.givenSize.maxValue">The seat’s storage size failed to save because it exceeds the enterprise’s available storage.</span>
              <span v-else-if="!$v.givenSize.minValue">Please enter a number greater than user used storage.</span>
              <span v-else-if="!$v.givenSize.cantStartWithZero">Please enter a number greater than 0.</span>
            </div>
          </div>
          <span class="value-size-form ml-3">GB</span>
        </div>
      </form>
      <div class="d-flex justify-content-end align-items-center mt-3 close-and-submit-enterprise">
        <span class="mr-3" @click="close()">Cancel</span>
        <button
          @click="submit()"
          :disabled="$v.$invalid || givenSize == null"
          class="ml-3"
          >
          <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
          Save</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      givenSize: null,
      loading: false
    }
  },
  props: ["member", "currentEnterprise"],
  validations() {
    return {
      givenSize: {
        required,
        minValue: minValue(this.convertByteToGBCeil(this.member.used_storage)),
        maxValue: maxValue(this.convertByteToGB(this.currentEnterprise.available_storage)),
        cantStartWithZero (value) {
          if (value === '') return true
          var regex = /^[1-9][0-9]*$/;
  
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(regex.test(value))
            }, 350 + Math.random()*300)
          })
        },
      }
    }
  },
  mounted(){
    this.reset()
  },
  methods: {
    reset(){
      this.givenSize = this.convertByteToGB(this.member.available_storage)
    },
    convertByteToGB(byte) {
      var gigabyte = byte / (1024 * 1024 * 1024);
      return gigabyte.toFixed(0);
    },
    convertByteToGBCeil(byte) {
      var gigabyte = byte / (1024 * 1024 * 1024);
      return Math.ceil(gigabyte);
    },
    convertGBToByte(gigabytes) {
      var byte = gigabytes * Math.pow(1024, 3);
      return byte.toFixed(0);
    },
    validationStatus(validation) {
      return typeof validation != undefined ? validation.$error: false
    },
    close() {
      this.$bvModal.hide('modal-edit-member-enterprise')
      this.givenSize = this.convertByteToGB(this.member.available_storage)
    },
    submit() {
      this.loading = true
      var payload = {
        id_user: this.member._id,
        size: parseInt(this.convertGBToByte(this.givenSize))
      }
      this.$store.dispatch('enterprise/editSeat', payload)
      .then(()=>{
        this.loading = false
        this.$bvModal.hide('modal-edit-member-enterprise')
        this.$toasted.show(`Seat’s storage size changed successfully.`,{ 
          theme: "toasted-primary", 
          position: "bottom-center", 
          duration : 2000,
          containerClass : 'toast-snackbar'
        })
      })
    },
  }
}
</script>

<style>

</style>