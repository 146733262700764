<template>
  <b-modal id="modal-remove-member-enterprise" no-close-on-backdrop hide-header hide-footer centered>
    <div class="d-flex flex-column">
      <span class="enterprise-text-desc">Remove member?</span>
      <span class="my-3 enterprise-alert-remove-text"><span>{{member.name != '-' ? member.name : member.email}}</span> will be removed from <span>{{companyName}}</span>. If you continue to remove, all files this member owns will be deleted permanently. </span>
      <span class="my-3 enterprise-member-list-text">Type "remove seat" to confirm.</span>
      <div class="input-custom-enterprise">
        <input
          v-model.trim="$v.confirmRemoveSeat.$model"
          @paste="onPaste"
          placeholder=""
          type="text"
          id="email-profile"
          :class="{'is-invalid': validationStatus($v.confirmRemoveSeat) && confirmRemoveSeat != '' && confirmRemoveSeat != null}">
        <div class="invalid-feedback">
          <span v-if="!$v.confirmRemoveSeat.sameAsText && confirmRemoveSeat != '' && confirmRemoveSeat != null" id="first-name-error" class="required-color error-message-v3">Please enter the text exactly as displayed.</span>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center mt-3 close-and-submit-enterprise">
        <span class="mr-3" @click="close()">Cancel</span>
        <button
          @click="submit()"
          class="ml-3"
          :disabled="$v.$invalid || confirmRemoveSeat == '' || confirmRemoveSeat == null"
          >
          <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
          Remove</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { sameAs } from 'vuelidate/lib/validators'

export default {
  data(){
    return {
      loading: false,
      removeText: 'remove seat',
      confirmRemoveSeat: ''
    }
  },
  props: ['member', 'companyName'],
  validations: {
    confirmRemoveSeat: {
      sameAsText: sameAs('removeText'),

    }
  },
  methods: {
    onPaste(event){
      event.preventDefault()
      this.$toasted.show(`You can't paste value`,{ 
        theme: "toasted-primary", 
        position: "bottom-center", 
        duration : 2000,
        containerClass : 'toast-snackbar'
      })
    },
    close() {
      this.confirmRemoveSeat = ''
      this.$bvModal.hide('modal-remove-member-enterprise')
    },
    validationStatus(validation) {
      return typeof validation != undefined ? validation.$error: false
    },
    submit() {
      var payload = {
        enterprise: this.member.id_enterprise,
        id_user_enterprise: this.member._id,
        confirmation: this.confirmRemoveSeat
      }
      this.loading = true
      this.$store.dispatch('enterprise/removeSeat', payload)
      .then(()=>{
        this.loading = false
        this.confirmRemoveSeat = ''
        this.$bvModal.hide('modal-remove-member-enterprise')
        this.$toasted.show(`Seat removed from ${this.companyName}`,{ 
          theme: "toasted-primary", 
          position: "bottom-center", 
          duration : 2000,
          containerClass : 'toast-snackbar'
        })
      })
    },
  }
}
</script>

<style>

</style>